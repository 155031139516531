<div class="account-pages my-1 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Bienvenido</h5>
                  <p>Usted está ingresando a la plataforma de MUEVE</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="assets/images/login/pattern_4.png"
                  alt=""
                  class="img-fluid"
                  style="pointer-events: none"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <!-- <div>
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img
                    src="assets/images/login/mueve_circle.png"
                    alt=""
                    class=""
                    height="34"
                  />
                </span>
              </div>
            </div> -->
            <div class="p-2">
              <form
                class="form-horizontal"
                [formGroup]="loginForm"
                (ngSubmit)="onSubmit()"
              >
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{
                  error
                }}</ngb-alert>

                <div class="mb-3">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    formControlName="email"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                  />
                  <div
                    *ngIf="submitted && f.email.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">
                      Email must be a valid email address
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="password">Contraseña</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input
                      [type]="showPassword ? 'text' : 'password'"
                      class="form-control"
                      formControlName="password"
                      placeholder="Ingresar contraseña"
                      [ngClass]="{
                        'is-invalid': submitted && f.password.errors
                      }"
                      aria-label="Password"
                      aria-describedby="password-addon"
                    />

                    <button
                      class="btn btn-light ms-0"
                      type="button"
                      id="password-addon"
                      (click)="togglePassword()"
                    >
                      <i class="mdi mdi-eye-outline"></i>
                    </button>
                    <div
                      *ngIf="submitted && f.password.errors"
                      class="invalid-feedback"
                    >
                      <span *ngIf="f.password.errors.required"
                        >Password is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="remember-check"
                  />
                  <label class="form-check-label" for="remember-check">
                    Recordar
                  </label>
                </div>

                <div class="mt-3 d-grid">
                  <button class="btn btn-primary" type="submit">
                    Ingresar
                  </button>
                </div>

                <!-- <div class="mt-4 text-center">
                  <a routerLink="/reset-password" class="text-muted"
                    ><i class="mdi mdi-lock mr-1"></i> Olvidé mi contraseña</a
                  >
                </div> -->
              </form>
            </div>
          </div>
        </div>
        <div class="mt-3 text-center">
          <p>
            ¿No tienes una cuenta?
            <a routerLink="/register" class="fw-medium text-primary">
              Registrate ahora
            </a>
          </p>
        </div>
        <!-- <p class="text-center">© {{ year }} VirtualDEV</p> -->
      </div>
    </div>
  </div>

  <!-- end container -->
</div>
<!-- end page -->
