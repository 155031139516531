<div class="container-fluid">
  <h1 class="title-views">Rubros</h1>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div
                class="search-box me-2 mb-2 d-inline-block"
                style="width: 15vw"
              >
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Buscar rubro"
                    (input)="onSearchInputChange($event)"
                    [(ngModel)]="term"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">
                <button
                  type="button"
                  class="btn btn-success btn-rounded mb-2 mr-2"
                  (click)="openModal(content)"
                >
                  <i class="mdi mdi-plus me-1"></i> Nuevo Rubro
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table align-middle table-nowrap">
              <thead>
                <tr>
                  <th>Descripción</th>
                  <th>Editar</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let rubro of rubros
                      | customfilter : term : 'rubroDescripcion'
                      | paginate : { itemsPerPage: 10, currentPage: p }
                  "
                >
                  <td>{{ rubro.rubroDescripcion }}</td>
                  <td style="width: 0px">
                    <button
                      class="btn btn-success"
                      (click)="editRubro(content2, rubro)"
                    >
                      <i class="bx bxs-edit-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            *ngIf="!loadedData"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="loader"></span>
          </div>
          <div *ngIf="loadedData">
            <pagination-controls
              previousLabel="Anterior"
              nextLabel="Siguiente"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Agregar rubro -->
  <ng-template #content role="document" let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Agregar Rubro</h5>
      <button
        type="button"
        class="btn-close"
        aria-hidden="true"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <form (ngSubmit)="saveRubro()" [formGroup]="formData">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label class="control-label">Descripción</label>
              <input
                class="form-control"
                placeholder="Ingresa una descripción"
                type="text"
                name="title"
                autocomplete="off"
                formControlName="rubroDescripcion"
              />
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-success" id="btn-save-event">
          Guardar
        </button>
      </form>
    </div>
  </ng-template>

  <!-- Editar rubro -->
  <ng-template #content2 role="document" let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Editar Rubro</h5>
      <button
        type="button"
        class="btn-close"
        aria-hidden="true"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <form (ngSubmit)="actualizarRubro()" [formGroup]="formData">
        <input type="hidden" formControlName="rubroProductoIdentity" />
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label class="control-label">Descripción</label>
              <input
                class="form-control"
                [value]="selectedRubro.rubroDescripcion"
                type="text"
                name="title"
                autocomplete="off"
                formControlName="rubroDescripcion"
              />
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-success" id="btn-save-event">
          Actualizar
        </button>
      </form>
    </div>
  </ng-template>
</div>
