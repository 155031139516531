<div class="contenido-layout" [class.contenido-layout]="!isAuthPage()">
  <div id="sidebar-menu" *ngIf="!isAuthPage()">
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-lg">
            <img
              src="assets/images/login/mueve_dark_mode.png"
              alt=""
              height="45"
            />
          </span>
        </a>
      </div>
      <!-- Titulos del Sidebar -->
      <li class="menu-title" *ngIf="!isAdmin">Dador de Carga</li>
      <li class="menu-title" *ngIf="isAdmin">Administrador</li>  
      
      <!-- Dashboards -->
      <li>
        <a href="/dashboard" class="side-nav-link-ref" routerLinkActive="active">
          <i class="bx bx-home-circle"></i>
          <span>Menú General</span>
        </a>
      </li>
      <li *ngIf="isAdmin">
        <a href="/usuarios" class="side-nav-link-ref" routerLinkActive="active">
          <i class="bx bxs-user"></i>
          <span>Usuarios</span>
        </a>
      </li>
      <!-- Apps -->
      <li class="menu-title">Aplicaciones</li>
      <li *ngIf="isAdmin">
        <a
          href="/productos"
          class="side-nav-link-ref"
          routerLinkActive="active"
        >
          <i class="bx bx-package"></i>
          <span>Productos</span>
        </a>
      </li>
      <li>
        <a href="cargas" class="side-nav-link-ref" routerLinkActive="active">
          <i class="bx bxs-truck"></i>
          <span>Cargas</span>
        </a>
      </li>
      <li *ngIf="!isAdmin">
        <a
          href="/notificaciones"
          class="side-nav-link-ref"
          routerLinkActive="active"
        >
          <i class="bx bx-bell"></i>
          <span style="margin-right: .5rem;">Notificaciones</span>
          <span class="badge rounded-pill bg-success text-white">{{notificationsQuantity}}</span>
        </a>
      </li>
      <li *ngIf="isAdmin">
        <a href="/pagos" class="side-nav-link-ref" routerLinkActive="active">
          <i class="bx bx-wallet"></i>
          <span>Pagos</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="contenido">
    <div class="topbar" *ngIf="!isAuthPage()">
      <div ngbDropdown>
        <div class="btn-contain">
          <i class='bx bxs-user-circle'></i>
          <button class="btn-custom" id="gfg" ngbDropdownToggle>
            {{ userbymail | truncateEmail }}
          </button>
        </div>
        <div ngbDropdownMenu="gfg">
          <button ngbDropdownItem (click)="logout()" style="display: flex; gap: .25rem; align-items: center;">
            <i class='bx bx-power-off' style="color: #d14e4e; font-size: 1rem; "></i>Cerrar sesión</button>
        </div>
      </div>
    </div>
    <div class="contenido-total">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
